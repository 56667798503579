<template>
	<div class="documents-dashboard__header">
		<template v-if="!activeRole">
			<Dropdown @change="checkIsOpen">
				<div class="documents-dashboard__header-roles">
					<p class="documents-dashboard__header-roles-title">
						{{ currentSpec }}
					</p>
					<div
						class="documents-dashboard__header-roles-icon"
						:class="{'open': isOpen}"
					>
						<svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M10 1L5.5 5.5L1 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
					<p
						v-if="roles && count"
						class="documents-dashboard__header-roles-count"
					>
						{{ count }}
					</p>
				</div>
				<template #menu>
					<div class="documents-dashboard__header-roles-items-wr">
						<span
							v-for="item in roles"
							:key="`${item.name}+${item}`"
							class="documents-dashboard__header-roles-item"
							@click="redirect(item)"
						>
							{{ item.name }}
						</span>
					</div>
					<span class="documents-dashboard__header-roles-item" @click="$router.push('/documents/dashboard/')">
						Все роли
					</span>
				</template>
			</Dropdown>
		</template>
		<template v-else>
			<div class="documents-dashboard__header-active-role-wr">
				<p class="documents-dashboard__header-active-role">
					{{ activeRole }}
				</p>
				<div @click="redirectToAllKnowledges">
					<Icon
						class="documents-dashboard__header-marks-icon"
						name="close"
						size="25"
					/>
				</div>
			</div>
		</template>
		<div class="documents-dashboard__header-wr">
			<template v-if="showSettings && isAdmin">
				<template v-if="!isExpanded && hasKnows">
					<div class="documents-dashboard__header-settings-item documents-dashboard__header-settings-item--small" @click="expandBlock">
						<div class="documents-dashboard__header-settings-item-square" />
						<span>Выбрать несколько</span>
					</div>
				</template>
				<template v-else-if="hasKnows">
					<div class="documents-dashboard__header-settings-wr" v-click-outside="expandBlock">
						<div class="documents-dashboard__header-settings-item" @click="deleteChecked">
							<Icon name="delete" size="14" />
							<span>Удалить</span>
						</div>
						<div v-if="showMerge" class="documents-dashboard__header-settings-item" @click="merge">
							<Icon name="merge" size="14" />
							<span>Объединить в категорию</span>
						</div>
						<div class="documents-dashboard__header-settings-item" v-if="showMerge" @click="removeChecked">
							<div class="documents-dashboard__header-settings-item-square" />
							<span>Снять выделение</span>
						</div>
					</div>
				</template>
			</template>
			<div v-if="marks && marks.length" class="documents-dashboard__header-marks">
				<div
					class="documents-dashboard__header-marks-checked"
					v-for="el in marks.filter(el => el.checked)"
					:key="el.id"
				>
					<div
						v-if="marks.filter(el => el.checked).length <= 3"
					>
					<div
						class="documents-dashboard__header-marks-checked-color"
						:style="{ 'background': el.color }"
						@click="changeMarkChecked(el.id)"
					>
						<svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M6.48528 0.827823L0.828427 6.48468M0.828427 0.827823L6.48528 6.48468" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</div>
					<span>
						{{ el.title }}
					</span>
					</div>
				</div>
				<span class="documents-dashboard__header-marks-checked-count" v-if="marks.filter(el => el.checked).length > 3">
					Выбрано несколько меток
				</span>
				<Dropdown
					:left="true"
				>
					<div class="documents-dashboard__header-marks-title">
						<Icon
							class="documents-dashboard__header-marks-icon"
							name="iconly/filter"
							size="18"
						/>
						<span v-if="isThereCheckedMark && isAdmin">Метки</span>
					</div>
					<template #menu>
						<div class="documents-dashboard__header-marks-wr">
							<div
								class="documents-dashboard__header-marks-item"
								v-for="item in listMarks"
								:key="item.id"
								@click="changeMarkChecked(item.id)"
							> 
								<div
									class="documents-dashboard__header-marks-item-color"
									:style="[{ 'border-color': item.color }, item.checked ? { 'background': item.color } : '']"
								/>
								<p
									class="documents-dashboard__header-marks-item-text"
									:class="{'active': item.checked}"
								>
									{{ item.title }}
								</p>
							</div>
						</div>
						<div
							class="documents-dashboard__header-marks-remove"
							@click="uncheckAllMarks"
						>
							Убрать все
						</div>
					</template>
				</Dropdown>
			</div>
		</div>
	</div>
</template>

<script>
// import components
import Dropdown from '@/components/ui/dropdown';
import Icon     from '@/components/ui/icon/Icon.vue';
import router from '@/router';

export default {
	name: 'DocumentsDashboardHeader',

	props:
	{
		marks:
		{
			type: Array,
			default: () => [],
		},
		currentSpec:
		{
			type: String,
			default: () => 'Все роли',
		},
		hasKnows:
		{
			type: Boolean,
			default: true,
		},
		listMarks: 
		{
			type: Array,
			default: () => [],
		},
		activeRole:
		{
			type: String,
			default: '',
		},
		showSettings:
		{
			type: Boolean,
			default: false,
		},
		isExpanded:
		{
			type: Boolean,
			default: false,
		},
		showMerge:
		{
			type: Boolean,
			default: false,
		},
		roles:
		{
			type: Array,
			default: () => [],
		},
		count:
		{
			type: Number,
			default: 0,
		},
	},

	components:
	{
		Icon,
		Dropdown,
	},

	data()
	{
		return {
			isOpen    : false,
		};
	},

	methods:
	{
		expandBlock()
		{
			this.$emit('expand', !this.isExpanded)
			this.removeChecked();
		},

		redirect(item)
		{
			const routePath = this.$route.path.split('/')
			item.id != routePath.pop() ?
				(this.$router.push(`./${item.id}`)) : null
		},

		checkIsOpen(value)
		{
			this.isOpen = value;
		},

		changeMarkChecked(markId)
		{
			this.$emit('changeMark', markId)
		},

		removeChecked() { this.$emit('removeChecked') },

		deleteChecked() { this.$emit('deleteChecked') },

		merge() { this.$emit('merge') },

		uncheckAllMarks()
		{
			if (this.marks.some(el => el.checked))
				this.$emit('uncheckAllMarks');
		},

		redirectToAllKnowledges()
		{
			if (this.$router.currentRoute.path !== '/documents/dashboard')
				this.$router.push('/documents/dashboard');
		},
	},

	computed:
	{
		isThereCheckedMark()
		{
			return !this.marks.some(el => el.checked === true)
		},

		isAdmin()
		{
			return this.roles.some(el => el.is_admin === true)
		},
	},
}
</script>

<style lang="scss" scoped>
	.documents-dashboard__header
	{
		margin-bottom: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 20px;
	}

	.documents-dashboard__header-roles
	{
		display: flex;
		align-items: center;
		gap: 10px;

		p { margin: 0; }
	}

	.documents-dashboard__header-roles-title
	{
		font-size: 24px;
		font-weight: 400;
		line-height: 29px;
		letter-spacing: 0em;
		color: #FFFFFF;
	}

	.documents-dashboard__header-roles-icon
	{
		transition: all .2s ease;

		&.open { transform: rotate(180deg); }
	}

	.documents-dashboard__header-roles-count
	{
		font-size: 14px;
		font-weight: 300;
		line-height: 17px;
		letter-spacing: 0em;
		color: #959595;
	}

	.documents-dashboard__header-roles-items-wr
	{
		max-height: 150px;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;

		&::-webkit-scrollbar { width: 8px; }
		&::-webkit-scrollbar-track { background: transparent; }
		&::-webkit-scrollbar-thumb {
			background-color: rgb(10, 10, 10);
			border-radius: 20px;
		}

		.documents-dashboard__header-roles-item:last-of-type { padding-bottom: 25px; }
	}

	.documents-dashboard__header-roles-items-wr + .documents-dashboard__header-roles-item
	{
		padding: 16px 25px;
		border-top: 0.5px solid #4F5152;
	}

	.documents-dashboard__header-roles-item
	{
		padding: 25px 25px 0 25px;
		max-width: 150px;
		word-break: break-word;
		cursor: pointer;
		font-size: 16px;
		font-weight: 400;
		line-height: 20px;
		letter-spacing: 0em;
		color: #FFFFFF;
		transition: all .2s ease;

		&:hover { color: #F7D547; }
	}

	.documents-dashboard__header-marks
	{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 10px;
	}

	.documents-dashboard__header-marks-title
	{
		display: flex;
		align-items: center;
		gap: 6px;

		span
		{
			font-size: 12px;
			font-weight: 300;
			line-height: 17.28px;
			color: #FFFFFF80;
		}
	}

	.documents-dashboard__header-marks-icon { cursor: pointer; }

	.documents-dashboard__header-marks-wr
	{
		max-height: 150px;
		overflow-y: scroll;
		display: flex;
		flex-direction: column;

		&::-webkit-scrollbar { width: 8px; }
		&::-webkit-scrollbar-track { background: transparent; }
		&::-webkit-scrollbar-thumb {
			background-color: rgb(10, 10, 10);
			border-radius: 20px;
		}

		padding: 25px;
		gap: 13px;
	}

	.documents-dashboard__header-marks-item
	{
		cursor: pointer;
		width: 150px;
		display: flex;
		align-items: center;
		gap: 7px;

		.documents-dashboard__header-marks-item-color
		{
			max-width: 13px;
			width: 100%;
			height: 11px;
			border-radius: 8px;
			border: 1px solid #4F5152;
			transition: all .2s ease;
		}

		.documents-dashboard__header-marks-item-text
		{
			word-break: break-word;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			padding: 0;
			font-size: 14px;
			font-weight: 400;
			line-height: 17px;
			letter-spacing: 0em;
			text-align: left;
			color: #FFFFFFB2;
			transition: all .2s ease;

			&.active { color: #FFFFFF; }
		}

		&:hover
		{
			.documents-dashboard__header-marks-item-text { color: #FFFFFF }
		}
	}

	.documents-dashboard__header-marks-checked
	{
		div
		{
			display: flex;
			align-items: center;
			gap: 5px;
		}

		span
		{
			font-size: 11px;
			font-weight: 300;
			line-height: 16px;
			letter-spacing: 0em;
			color: #FFFFFFCC;
		}
	}

	.documents-dashboard__header-marks-checked-count
	{
		font-size: 12px;
		cursor: default;
	}

	.documents-dashboard__header-marks-checked-color
	{
		cursor: pointer;
		width: 19px;
		height: 15px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.documents-dashboard__header-marks-remove
	{
		cursor: pointer;
		border-top: 0.5px solid #4F5152;
		padding: 13px 25px;
		font-size: 14px;
		font-weight: 400;
		line-height: 17px;
		letter-spacing: 0em;
		color: #FFFFFFB2;
		transition: all .2s ease;

		&:hover { color: #FFFFFF }
	}

	.documents-dashboard__header-active-role-wr
	{
		display: flex;
		align-items: center;
		gap: 10px;

		div { height: 25px; }
		svg
		{
			width: 25px;
			height: 25px;
		}
	}

	.documents-dashboard__header-active-role
	{
		margin: 0;
		font-size: 24px;
		font-weight: 400;
		line-height: 29px;
		letter-spacing: 0em;
	}

	.documents-dashboard__header-wr
	{
		position: relative;
		display: flex;
		align-items: center;
		gap: 30px;
	}

	.documents-dashboard__header-settings-item
	{
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 6px;

		span
		{
			font-size: 14px;
			font-weight: 300;
			line-height: 16.8px;
			color: #FFFFFFB2;
			transition: all .5s ease;
		}

		&--small
		{
			font-size: 12px;
			font-weight: 300;
			line-height: 17.28px;
			color: #FFFFFF80;
		}

		&:hover {
			span { color: white; }
			.icon { color: white; }
			.documents-dashboard__header-settings-item-square { border-color: white; }
		}
	}

	.documents-dashboard__header-settings-item-square
	{
		max-width: 12px;
		max-height: 12px;
		width: 12px;
		height: 12px;
		border: 1px solid #FFFFFF80;
		border-radius: 3px;
		transition: border .5s ease;
	}

	.documents-dashboard__header-settings-wr
	{
		position: absolute;
		right: 90px;
		width: max-content;
		padding: 15px 20px;
		border: 0.7px solid #474747;
		background-color: #191919;
		box-shadow: 0px 4px 40px 0px #0000004D;
		border-radius: 19px;

		display: flex;
		align-items: center;
		gap: 20px;
	}
</style>