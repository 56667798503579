<template>
	<div
		class="documents-dashboard__item-header"
		@mouseenter="hovered = true"
		@mouseleave="hovered = false"
	>
		<div class="documents-dashboard__item-header-inner">
			<h3 class="documents-dashboard__item-header-name">
				{{ title }}
			</h3>
			<Icon v-if="pinned" name="bookmark" size="11" class="documents-dashboard__item-header-bookmark" />
		</div>
		<div class="documents-dashboard__item-header-options-wr">
			<div
				v-if="hovered || showOptions"
				class="documents-dashboard__item-header-options-icon"
				:class="{'active': showOptions}"
				@click="showOptions = !showOptions"
			>
				<Icon name="more" size="14" />
			</div>
			<div
				v-if="showOptions"
				class="documents-dashboard__item-header-options"
				v-click-outside="closePopup"
			>
				<div
					v-if="canEdit"
					class="documents-dashboard__item-header-options-item"
					@click="optionEditCategoryOpen = true, hovered = false, showOptions = false"
				>
					<Icon name="edit" size="14" />
					<span>
						Редактировать
					</span>
				</div>
				<div
					v-if="pinned"
					class="documents-dashboard__item-header-options-item"
					@click="editPinState(false)"
				>
					<Icon name="bookmark" size="14" />
					<span>
						Открепить
					</span>
				</div>
				<div
					v-else
					class="documents-dashboard__item-header-options-item"
					@click="editPinState(true)"
				>
					<Icon name="bookmark" size="14" />
					<span>
						Закрепить
					</span>
				</div>
				<div
					v-if="canEdit"
					class="documents-dashboard__item-header-options-item documents-dashboard__item-header-options-item--delete"
					@click="showDeleteContext = true, hovered = false, showOptions = false"
				>
					<Icon name="delete" size="14" />
					<span>
						Удалить
					</span>
				</div>
			</div>
		</div>

		<DeleteContext v-if="showDeleteContext" @close="closeDeleteContext" @delete="deleteItem">
			Удалить категорию “{{ title }}”?
		</DeleteContext>

		<Editor
			v-if="optionEditCategoryOpen"
			header="Редактировать категорию"
			:valueLength="30"
			:value="{ name: title }"
			@cancel="closeEditor"
			@submit="updateCategoryName"
		/>
	</div>
</template>

<script>
// import components
import DeleteContext from '@/components/context-menu/repeatable/DeleteContext.vue';
import Icon          from '@/components/ui/icon/Icon.vue';
import Editor        from '@/modules/settings/views/directions/components/Editor.vue';

export default {
	name: 'DocumentsDashboardItemHeader',

	components:
	{
		Icon,
		DeleteContext,
		Editor,
	},

	props:
	{
		title:
		{
			type: String,
			default: '',
		},
		pinned:
		{
			type: Boolean,
			default: false,
		},
		canEdit:
		{
			type: Boolean,
			default: false,
		},
	},

	data()
	{
		return {
			hovered               : false,
			showOptions           : false,
			showDeleteContext     : false,
			optionEditCategoryOpen: false,
		};
	},

	methods:
	{
		closePopup()
		{
			this.showOptions = false;
		},

		closeDeleteContext()
		{
			this.showDeleteContext = false;
		},

		deleteItem()
		{
			this.showDeleteContext = false;
			this.$emit('deleteCategory');
		},

		editPinState(value)
		{
			this.$emit('editPinState', value);
		},

		updateCategoryName(value)
		{
			if (value.name)
			{
				this.$emit('updateCategoryName', value.name);
				this.optionEditCategoryOpen = false;
			}
		},

		closeEditor()
		{
			this.optionEditCategoryOpen = false;
		},
	},
}
</script>

<style lang="scss" scoped>
	.documents-dashboard__item-header
	{
		border-radius: 19px 19px 0 0;
		padding: 15px 25px;
		background: #2C2C2C;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.documents-dashboard__item-header-inner
	{
		display: flex;
		gap: 8px;
		align-items: center;
		color: #FFFFFF80;
	}

	.documents-dashboard__item-header-name
	{
		margin: 0;
		font-size: 18px;
		font-weight: 400;
		line-height: 22px;
		letter-spacing: 0em;
		color: #FFFFFF;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 1;
		display: -webkit-box;
		-webkit-box-orient: vertical;
	}

	.documents-dashboard__item-header-options-wr
	{
		position: relative;
	}

	.documents-dashboard__item-header-options-icon
	{
		cursor: pointer;
		color: #656565;
		transition: all .1s ease;

		&:hover
		{
			color: white;
		}

		&.active
		{
			color: white;
			transform: rotate(90deg);
		}
	}

	.documents-dashboard__item-header-options
	{
		z-index: 10;
		width: max-content;
		position: absolute;
		padding: 20px;
		background: #191919;
		border: 0.7px solid #FFFFFF33;
		border-radius: 10px;
		top: 25px;
		left: -10px;
		display: flex;
		flex-direction: column;
		gap: 20px;
	}

	.documents-dashboard__item-header-options-item
	{
		cursor: pointer;
		display: flex;
		align-items: center;
		gap: 10px;

		span
		{
			font-size: 14px;
			font-weight: 300;
			line-height: 17px;
			color: #FFFFFFCC;
		}

		&:hover
		{
			span { color: #FFFFFF; }
			.icon { color: #FFFFFF; }
		}

		&--delete
		{
			span { color: #9E4835; }
			.icon { color: #9E4835; }

			&:hover
			{
				span { color: red; }
				.icon { color: red; }
			}
		}
	}
</style>