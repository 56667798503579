<template>
	<div class="documents-dashboard__item">
		<ItemHeader
		:pinned="item.pinned"
		:title="item.name"
		:can-edit="userCanEdit"
		@deleteCategory="deleteCategory(item.id)"
		@editPinState="editPinState(item.id, $event)"
		@updateCategoryName="updateCategoryName(item.id, $event)"
		/>
		<List
		:class="{'is-empty': !item?.knowledges?.length}"
		:index="index"
		:data="item.knowledges"
		:can-edit="userCanEdit"
		:is-expanded="isExpanded"
		:is-admin="isAdmin"
		:is-pinned="item.pinned"
		:pinned="item.pinned"
		:has-subcategory="item.subcategories.some(el => el.knowledges.length)"
		@deleteItem="deleteItem(item.id, $event)"
		@onDrop="onDrop(index, $event)"
		@checkItem="checkItem"
		/>
		<div
		v-if="item.subcategories && item.subcategories.length && item.subcategories.some(el => el.knowledges.length)"
		class="documents-dashboard__item-subcategories-wr"
		>
		<div
		v-for="(el, subcategoryIndex) in item.subcategories"
		:key="el.id"
		>
		<template
		v-if="el.knowledges && el.knowledges.length"
		>
		<Subcategory
		:data="el" 
		:group-index="index"
		:container-index="subcategoryIndex"
		:user-can-edit="userCanEdit"
		:is-expanded="isExpanded"
		:knowsCount="knowsLength"
		:isAdmin="isAdmin"
		:expandedList="expandedList"
		@expandList="expandList($event)"
		@onSubcategoryDrop="onSubcategoryDrop"
		@deleteSubcategoryItem="deleteSubcategoryItem"
		@deleteSubcategory="deleteSubcategory"
		@checkItem="checkItem"
		@subcategoryUpdate="subcategoryUpdate"
		/>
	</template>
</div>
</div>
</div>
</template>

<script>
// import components
import ItemHeader  from '@/components/documents/dashboard-item/header.vue';
import Subcategory from '@/components/documents/dashboard-item/subcategory.vue';
import List        from '@/components/documents/dashboard-item/list.vue';
import PinModule 	 from '@/store/modules/pin/PinModule';

import ApiClient 	 from '@/api/api.client';


export default {
	name: 'DndCard',
	components: { ItemHeader, Subcategory, List },
	
	props: ['index', 'item', 'userCanEdit', 'isExpanded', 'isAdmin', 'expandedList'],

	data()
	{
		return {
			knowsLength: null,
		}
	},
	
	methods:
	{
		deleteCategory(id) { this.$emit('deleteCategory', id); },
		
		editPinState(id, state) { this.$emit('editPinState', { id, state } ); },
		
		updateCategoryName(id, value) { this.$emit('updateCategoryName', { id, value } ); },
		
		deleteItem(categoryId, itemId) { this.$emit('deleteItem', { categoryId, itemId } ); },
		
		onDrop(groupIndex, value)
		{
			if (!value.enabled)
				return
			this.$emit('onDrop', { groupIndex, value } );
		},
		
		subcategoryUpdate() { this.$emit('subcategoryUpdate'); },
		
		onSubcategoryDrop(value) { this.$emit('onSubcategoryDrop', value) },

		expandList(value) { this.$emit('expandList', value) },
		
		deleteSubcategoryItem(value) { this.$emit('deleteSubcategoryItem', value) },
		
		deleteSubcategory(value) { this.$emit('deleteSubcategory', value) },
		
		checkItem(value)
		{
			if (value.subcategoryId)
				this.$emit('checkItem', { category: this.item.id, subcategoryId: value.subcategoryId, knowledgeId: value.knowledgeId });
			else
				this.$emit('checkItem', { category: this.item.id, subcategoryId: null, knowledgeId: value });
		},

		countKnows(){
			this.knowsLength = this.item.knowledges.length
			this.item.subcategories.length ?
				this.item.subcategories.forEach(el => {
					this.knowsLength+= el.knowledges.length
				}) : null
		},
	},

	computed:
	{
	},

	mounted()
	{
		this.countKnows()
	},
}
</script>

<style lang="scss" scoped>
.documents-dashboard__item
{
	position: relative;
	border-radius: 19px;
	margin: 0 20px 30px 0;
	background: #222222;
	
	&::before
	{
		content: '';
		position: absolute;
		z-index: -1;
		top: -10px;
		width: 98%;
		border-radius: 19px;
		height: 50px;
		background: #2C2C2C99;
		left: 50%;
		transform: translateX(-50%);
	}
	
	.documents-dashboard__item-subcategories-wr:last-of-type { padding-bottom: 20px; }
}

.is-empty
{
	position: relative;
	margin-top: -60px;
	top: 30px;
}

.documents-dashboard__item-subcategories-wr { padding: 30px 30px 0 30px; }
</style>