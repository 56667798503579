<template>
	<div
		class="dnd-list"
		:class="{'has-subcategories': hasSubcategory}"
	>
		<Container
			:data-index="index"
			group-name="column"
			:get-child-payload="itemIndex => getChildPayload(itemIndex)"
			:should-accept-drop="()=> true"
			drag-class="card-ghost-drop"
			ref="con"
			@drop="onDrop($event)"
			@drag-start="startDragging($event)"
			@drop-ready="dropReady()"
			:drop-placeholder="dropPlaceholderOptions"
		>
			<component
				:is="dragType"
				v-for="child in data"
				:key="child.id"
				:class="{'smooth-dnd-undraggable-wrapper': !isAdmin}"
			>
				<ListItem
					:checked="child.checked"
					:is-new="child.is_new"
					:id="child.id"
					:link="child.link"
					:title="child.title"
					:tags="child.tags"
					:can-edit="canEdit"
					:isExpanded="isExpanded"
					@deleteItem="deleteItem"
					@checkItem="checkItem"
				/>
			</component>
		</Container>
	</div>
</template>

<script>
// import components
import { Container, Draggable } from 'vue-smooth-dnd';
import ListItem                 from '@/components/documents/dashboard-item/item.vue';
import PinModule 	 						  from '@/store/modules/pin/PinModule';



export default {
	name: 'DragAndDropList',

	props: ['data', 'index', 'canEdit', 'hasSubcategory', 'isExpanded', 'isAdmin', 'isPinned'],

	components: { ListItem, Container, Draggable },

	data()
	{
		return {
			dropPlaceholderOptions: {
				className: 'drop-preview',
				animationDuration: '100',
				showOnTop: true,
			},
		}
	},
	computed:
	{
		dragType() { return !this.isAdmin ? 'div' : Draggable; },

		enableDrop() { return PinModule.enabled; },
	},
	methods:
	{
		getChildPayload(itemIndex) { return this.data[itemIndex]; },

		onDrop(event)
		{
			const enabled = PinModule.enabled
			this.$emit('onDrop', { event, enabled });
		},

		deleteItem(value) { this.$emit('deleteItem', value); },

		checkItem(value) { this.$emit('checkItem', value); },

		startDragging(event) { event.isSource ? PinModule.setPinned(this.isPinned) : null },

		dropReady()
		{
			const enabled = this.isPinned == PinModule.pinned ? true : false
			PinModule.setEnabled(enabled)
		},
	},
}
</script>

<style lang="scss">
	.dnd-list
	{
		padding: 30px;
		.smooth-dnd-container { position: relative; }
		.smooth-dnd-draggable-wrapper,
		.smooth-dnd-undraggable-wrapper
		{ padding: 7.5px 0; overflow: visible!important }
		.smooth-dnd-undraggable-wrapper .documents-dashboard__item-knowledge
		{ cursor: pointer }
		&.has-subcategories { padding-bottom: 0; }

	}

	.card-ghost-drop
	{
		padding: 8px 12px;
		background: #2C4170;
		border-radius: 8px;
		color: #FFFFFF;
	}

	.drop-preview
	{
		margin: 5px 0;
		background: #2C4170;
		border-radius: 8px;
		color: #FFFFFF;
	}
</style>