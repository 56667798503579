import { getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import StoreModules from '@/store/StoreModules';
import store from '@/store';

@Module({
    dynamic: true,
    name: StoreModules.Pin,
    namespaced: true,
    store,
})
class PinModule extends VuexModule
{
  isPinned = false;
  enableDrop = true;

  get pinned()
  {   
    return this.isPinned
  }

  get enabled()
  {   
    return this.enableDrop
  }

  @Mutation
  setPinned(isPinned: boolean)
  {   
    this.isPinned = isPinned
  }

  @Mutation
  setEnabled(isEnabled: boolean)
  {   
    this.enableDrop = isEnabled
  }
}
export default getModule(PinModule);